<template>
  <v-container
    style="height: calc(100vh - 60px); overflow-y: auto"
    fluid
    class="py-0"
  >
    <SimpleTable
      :headers="headers"
      creatable
      editable
      removable
      item-name="Học phần"
      :url="url"
      class="elevation-1"
    />
  </v-container>
</template>

<script>
import SimpleTable from "@/components/table/SimpleTable.vue";

export default {
  components: {
    SimpleTable
  },

  data() {
    return {
      headers: [
        {
          text: "Tên",
          value: "name",
          rules: "required"
        },
        {
          text: "Mã học phần",
          value: "code",
          rules: "required"
        }
      ]
    };
  },

  computed: {
    url() {
      const url = "/subjects"
      if (this.$store.state.branch.owner) {
        return url + '?owner=true'
      } else {
        return url
      }
    }
  },

  methods: {}
};
</script>
